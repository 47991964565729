.life{
    width: 100%;
    padding: 40px;
}

.life-img{
  height: auto;
  width: 500px;
  margin-left: auto; /* Tekerje jobbra a képet */
  margin-left: 30%; /* Adj egy kis távolságot a jobb oldaltól */
  border-radius: 0 7rem 0 7rem;
  box-shadow: 20px 20px 0 0 #967375; 
}

.about-text{
    width: 400px;
}

.main3{
    width: 1130px;
    max-width: 95%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.life-respo{
  width: 1130px;
    max-width: 95%;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    color: black;
    letter-spacing: 1px;
  font-size: 18px;
  margin-bottom: 45px;
  height: 40%;
  line-height: 28px;
 
}

.about-text h1{
    color: black;
    font-size: 36px;
    text-transform: capitalize;
    margin-bottom: 20px;
    /* padding-top: 45px; */
}

.about-text h5{
    color: black;
    font-size: 25px;
    text-transform: capitalize;
    margin-bottom: 25px;
    letter-spacing: 2px;
}

.about-text p{
    color: black;
    letter-spacing: 1px;
    line-height: 28px;
    font-size: 18px;
    margin-bottom: 45px;
    height: 40%;
}

.buttonRight{
    text-decoration: none;
    border: 2px solid transparent;
    font-weight: bold;
    padding:  13px 30px;
    border-radius: 30px;
    transition: .4s; 
    background: "#CAF0F8"; 
    border-radius: "100px";
    color:"#6E6D6E";
    letter-spacing:"2px";
    font-family:"Montserrat,Helvetica,Arial,Lucida,sans-serif";
}

.buttonRight:hover{
    background: transparent;
    border: 2px solid;
}


@media screen and (max-width: 600px) {
    .main3 {
      flex-direction: column;
      align-items: center;
    }
  
    .life img {
      width: 80%;
      max-width: 300px;
      border-radius: 0 5rem 0 5rem;
      box-shadow: 20px 20px 0 0 #967375;
      margin-left: 0px;
      justify-content: center;
      margin-bottom: 20px;
    }
  
    .about-text {
      width: 100%;
      text-align: center;
    }
  
    .about-text h1 {
      font-size: 24px;
      margin-bottom: 15px;
    }
  
    .about-text h5 {
      font-size: 20px;
      margin-bottom: 20px;
    }
  
    .about-text p {
      font-size: 14px;
      margin-bottom: 30px;
    }
  
    button {
      font-size: 14px;
      padding: 10px 20px;
      margin-top: 20px; 
      order: 2; 
      margin-bottom: 20px;
    }
  }

  @media screen and (min-width: 601px) and (max-width: 1024px) {
    .life {
        padding: 60px 0;
    }

    .main3 {
        flex-direction: column;
        align-items: center;
    }

    .life img {
        width: 80%;
        max-width: 300px;
        margin-right: 100px;
        border-radius: 0 7rem 0 7rem;
        box-shadow: 20px 20px 0 0 #967375;
    }

    .life-respo {
      text-align: center !important;
      width: 80%;
    }
    .about-text {
        width: 80%;
        text-align: center !important;
        font-size: 16px;
        margin-bottom: 40px;
    }

    .about-text h1 {
        font-size: 28px;
        margin-bottom: 20px;
    }

    .life-respo h3{
      font-size: 24px;
      margin-bottom: 25px;
    }
    .about-text h5 {
        font-size: 24px;
        margin-bottom: 25px;
    }

    .about-text p {
        font-size: 16px;
        margin-bottom: 40px;
    }
}

@media only screen and (max-width: 768px) {
  .life-respo h3,
  .life-respo p {
    
  }

  .life-respo h3 {
    font-size: 1.5em; /* Adjust font size for headers on smaller screens */
  }

  .life-respo p {
    font-size: 16px; /* Adjust font size for paragraphs on smaller screens */
    margin-bottom: 20px; /* Adjust margin for paragraphs on smaller screens */
    height: auto; /* Remove fixed height for paragraphs */
    line-height: 1.5; /* Adjust line height for better readability */
  }
}

@media screen and (min-width: 298px) and (max-width: 450px) {
  .life img {
    width: 90%;
    max-width: 250px;
    margin-right: 20px;
    border-radius: 0 5rem 0 5rem;
    box-shadow: 20px 20px 0 0 #967375;
    margin-bottom: 20px;
}

.main3{
  margin-bottom: 20px;
}
.life-respo {
  text-align: center !important;
  width: 80%;
  font-size: 14px;
  text-align: center !important;
   line-height: 0;
   color: black;
    letter-spacing: 1px;
    line-height: 28px;
    font-size: 18px;
    margin-bottom: 45px;
    height: 40%;
}
.about-text {
    /* width: 80%; */
    text-align: center !important;
    font-size: 18px;
    /* margin-bottom: 40px; */
    color: black;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 45px;
    height: 40%;
}

.life{
  padding: 0;
}
.life-respo p {
  font-size: 12px; /* Adjust font size for paragraphs on smaller screens */
  text-align: center !important;
  color: black;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 45px;
    height: 40%;
}

.about-text h1 {
    font-size: 20px;
    margin-bottom: 20px;
    padding-top: 0;
}

.life-respo h3{
  font-size: 18px;
  margin-bottom: 25px;
  margin-top: 10px;
}
.about-text h5 {
    font-size: 18px;
    margin-bottom: 25px;
}

.about-text p {
    font-size: 12px;
    /* margin-bottom: 40px; */
    text-align: center !important;
    color: black;
    letter-spacing: 1px;
    line-height: 28px;
    margin-bottom: 45px;
    height: 40%;
}
}