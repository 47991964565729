.complaint {
    /* text-align: center; */
  }
  
  .complaint > div {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
  }
  
  .complaint p {
    margin-bottom: 15px;
  }

.complaint-li{
    list-style: inside;
}