.about {
  margin-bottom: 80px;
}

.about .heading {
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
}

.about p {
  font-size: 15px;
  padding-right: 30px;
  margin-bottom: 20px;
  line-height: 30px;
}

.left-row {
  width: 60%; /* Adj meg egy szélességet a bal oldali elemnek */
}

.left-row img {
  height: auto;
  width: 500px;
  margin-left: auto; /* Tekerje jobbra a képet */
  margin-left: 30%; /* Adj egy kis távolságot a jobb oldaltól */
  border-radius: 0 7rem 0 7rem;
  box-shadow: 20px 20px 0 0 #967375; 
}

.about-img{
  height: auto;
  width: 500px;
  margin-left: auto; 
  border-radius: 0 7rem 0 7rem;
  box-shadow: 20px 20px 0 0 #967375; 
}

@media screen and (max-width: 768px) {
  .about .container {
    flex-direction: column;
    align-items: center; /* Középre igazítás */
  }

  .about p {
    padding: 0;
  }

  .left-row {
    width: 100%; /* Teljes szélesség mobil nézetben */
  }

  .left-row img {
    width: 60%; /* Kisebb méretű kép mobil nézetben */
    margin-top: 20px; /* Kevesebb felső margó mobil nézetben */
  }

  .about-img{
   
      height: auto;
      width: 350px;
      margin-left: auto;
      border-radius: 0 7rem 0 7rem;
      box-shadow: 20px 20px 0 0 #967375;

  }
}

@media screen and (min-width: 298px) and (max-width: 450px) {
  .about-img{
   
    height: auto;
    width: 250px;
    margin-right: 20px;
    border-radius: 0 5rem 0 5rem;
    box-shadow: 20px 20px 0 0 #967375;

}

.about p {
  font-size: 12px;
  text-align: center;
}
.about h2{
  font-size: 20px;
  text-align: center;
}


}
