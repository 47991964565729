.map {
    position: relative;
  }
  
  .map iframe {
    width: 100%;
    height: 300px;
    border: 0;
  }
  
  #menux .row {
    display: flex;
    justify-content:  space-around;
    align-items: stretch;
  }
  
  .row-map {
    width: 160%;
  }
  
  @media screen and (max-width: 900px) {
    #menux .row {
      flex-direction: column;
    }
  
    .row-map {
      width: 100%;
    }
  }

.container{
  padding-bottom: 30px;
}

@media screen and (min-width: 298px) and (max-width: 450px) {
  .input-box p{
    font-size: 18px;
  }

  .heading{
    font-size: 20px;
    padding: 0;
  }
}