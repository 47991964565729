.featured .box {
    box-shadow: 0 0 20px 0 #39201c;
    border-radius: 6px;
    text-align: center;
    padding: 30px;
    /* cursor: pointer; */
    background-color: #f3e8de;
  }
  .featured img {
    width: 65px;
    height: 65px;
    margin: auto;
  }

.feature-button {
  background-color: #f3e8de;
  color: black;
  font-size: 18px;
    font-weight: 500;
  border-radius: 6px;
    text-align: center;
    padding: 30px;
    cursor: pointer;
    margin-top: 10px
}

  @media screen and (max-width: 767px) {
    .featured .box {
      width: 100%; /* Teljes szélességű a mobil nézetben */
      margin-bottom: 20px; /* Vagy más megfelelő margin érték a mobil nézetben */
    }
}