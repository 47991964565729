.navbar{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content:center;
    align-items: center;
    padding: 0 1rem;
    z-index: 2;
    position: absolute;
    color: #000;
  }
  .navbar-bg {
    background-color: #846756;
  }
  
  .nav-menu {
    display: flex;
    cursor: pointer;
  }
  
  li {
    padding: 0 1rem;
  }
  
  .hamburger {
    display: none;
    /* padding: 1rem; */
  }
  
  .mobile-menu {
    display: none;
  }
  
  .icon {
    font-size: 1.5rem;
    cursor: pointer;
  }
  @media screen and (max-width: 940px) {
  
    .mobile-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        top: -110vh;
        left: 0;
        position: absolute;
        justify-content: space-between;
        /* align-items: center; */
        /* color: rgba(255, 255, 255, .95); */
        background-color: #846756;
        transition: 0.3s;
        color: #000;
    }
  
    .dark {
        color: #000;
    }
  
    .active {
        top: 80px;
    }
  
    .mobile-menu li {
        padding: 1.2rem 1rem;
        margin: 0 1rem;
        border-bottom: 1px solid #846756;
    }
  
    .mobile-nav {
        margin: 1rem 0;
    }
  
    .mobile-menu-bottom {
        width: 100%;
        text-align: center;
        padding: 1rem 0;
    }
  
    .social-icons {
        display: flex;
        justify-content: space-around;
        margin: 2rem 0;
    }
  
    .social-icons .icon:hover {
        transform: scale(1.5);
        transition: 0.3s;
    }
  
    .mobile-menu button {
        margin: 1rem;
        width: 90%;
    }
  
  
    .nav-menu {
        display: none;
    }
  
    .nav-icons {
        display: none;
    }
    .hamburger {
        display: block;
    }
  
  }
  
  ul, ol {
    list-style: none;
    padding-left: 0;
  } 
  
  .nav-menu{
    font-family: 'Montserrat',Helvetica,Arial,Lucida,sans-serif;
      font-weight: 300;
      font-size: 20px;
      line-height: 1.4em;
  }